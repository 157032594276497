import Cookies from 'js-cookie';
import pkg from 'tldjs';

const { getDomain } = pkg;

/**
 * Extracts the top-level domain from a given hostname.
 * If a top-level domain is successfully retrieved, it returns the domain prefixed with a dot (.)
 * to ensure cookies are stored at the top-level domain.
 * If no top-level domain is available, it returns the original hostname.
 * 
 * @param hostname - The hostname from which to extract the top-level domain.
 * @returns The top-level domain prefixed with a dot, or the original hostname if unavailable.
 */
function getTopLevelDomain(hostname: string) {
  const domain = getDomain(hostname);
  return domain ? `.${domain}` : hostname;
}

/**
 * Removes Google Analytics cookies from the browser.
 */
export function removeGACookies() {
  const gaGidGtagRegex = /^_g(a(_[A-Z0-9]+)?|id|at_gtag_)/;
  const cookies = Cookies.get();
  const domain = getTopLevelDomain(window.location.hostname);

  if (!domain) {
    console.warn('Unable to determine top-level domain.');
    return;
  }

  Object.keys(cookies).forEach((cookieName) => {
    if (gaGidGtagRegex.test(cookieName)) {
      Cookies.remove(cookieName, { path: '/', domain });
    }
  });
}
